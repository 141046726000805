<template>
    <v-form v-model="isValid">
        <div class="txt txt--sm text-right pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</div>

        <vertical-form-table v-model="form" v-bind="{ items }" @input="emit" />

        <div class="btn-wrap btn-wrap--lg">
            <v-row justify="center" class="row--sm">
                <v-col cols="6" sm="auto">
                    <v-btn tile v-bind="{ ...btn_primary, ...loading, disabled }" class="v-btn--width-fixed v-size--xx-large w-100 min-w-sm-200px" @click="$emit('save')">신청하기</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<script>
import { attrs_input, btn_primary, rules, initCenterContacts } from "@/assets/variables";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const items = [
    {
        key: "company",
        term: "업체명",
        type: "text",
        rules: rules.company,
        required: true,
        placeholder: "업체명을 입력하세요.",
        ...attrs_input,
    },
    {
        key: "homepage",
        term: "홈페이지 주소",
        type: "text",
        rules: rules.homepage,
        required: true,
        placeholder: "홈페이지 주소를 입력하세요.",
        ...attrs_input,
    },
    {
        key: "writer.name",
        term: "담당자 명",
        type: "text",
        rules: rules.name,
        required: true,
        placeholder: "담당자 명을 입력하세요.",
        ...attrs_input,
    },
    {
        key: "writer.email",
        term: "이메일",
        type: "email",
        // rules: rules.email,
        required: false,
        ...attrs_input,
    },
    {
        key: "writer.tel",
        term: "전화번호",
        type: "phone",
        // rules: rules.phone,
        required: false,
        ...attrs_input,
    },
    {
        key: "writer.phone",
        term: "휴대폰 번호",
        type: "phone",
        rules: rules.phone,
        required: true,
        ...attrs_input,
    },
    {
        key: "content",
        term: "내용",
        type: "textarea",
        rules: rules.content,
        placeholder: "내용을 입력하세요.",
        required: true,
        ...attrs_input,
    },
];

export default {
    components: {
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initCenterContacts },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initCenterContacts(),

        items,
        btn_primary,
        isValid: false,
    }),
    computed: {
        disabled() {
            return !this.isValid;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initCenterContacts(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
